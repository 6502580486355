
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  getMonthsForLocale,
  getDaysForLocale,
  displaySpanRange,
} from "@/views/therapistCalendar/helpers";
import { Session } from "@/interfaces/therapy";
import { post } from '@/api'

@Component({
  methods: {
    displaySpanRange,
  },
})
export default class TherapistBookedVisit extends Vue {
  @Prop()
  session!: Session;
  @Prop()
  isPastDate!: boolean;

  showWarning = false;
  visitCanceled = false;
  showMorePatientInfo = false;

  dayNumber(span: number) {
    return new Date(span).getDate();
  }

  monthName(span: number) {
    const monthNames = getMonthsForLocale("long", true);
    const date = new Date(span);
    return monthNames[date.getMonth()];
  }

  dayName(span: number) {
    const weekdayNames = getDaysForLocale("short", true);
    const date = new Date(span);
    return weekdayNames[date.getDay()];
  }

  hour(span: number) {
    const formatter = Intl.DateTimeFormat("en", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    return formatter.format(new Date(span));
  }

  async cancelBookedVisit() {
    try {
      await post.cancelSession(this.session.id);
      this.visitCanceled = true;
      this.showWarning = false;
    } catch (error) {
      console.log(error);
    }
  }
}
