
import { Therapy } from "@/interfaces";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import TherapistBookedVisit from "@/components/TherapistBookedVisit.vue";
import { getMonthsForLocale } from "@/views/therapistCalendar/helpers";

interface CalendarBookedModalProps {
  modal_date: Date;
  reloadSpans: Function;
}

@Component({
  components: {
    TherapistBookedVisit,
  },
})
export default class TherapistBookedModal extends Vue {
  @Prop()
  props!: CalendarBookedModalProps;

  display_date = "";
  modal_date!: Date;
  date_list!: Record<string, Array<Therapy.Session>>;
  disposed_spans: Therapy.Session[] = [];
  reloadSpans!: Function;

  created() {
    this.modal_date = new Date(this.props.modal_date.getTime());
    this.date_list = this.$store.state.calendar.therapistSpans;
    this.reloadSpans = this.props.reloadSpans;
    this.disposeSpans();
    this.renderDisplayDate();
  }

  isPastDate() {
    const currentDateToCompare = new Date();
    const modalDateToCompare = new Date(this.modal_date.getTime());
    currentDateToCompare.setHours(0, 0, 0, 0);
    modalDateToCompare.setHours(0, 0, 0, 0);

    if (currentDateToCompare <= modalDateToCompare)
      return false;
    return true;
  }

  changeDate(direction: number) {
    const day = this.modal_date.getDate();
    this.modal_date.setDate(day + direction);
    this.renderDisplayDate();
  }

  renderDisplayDate() {
    const monthNames = getMonthsForLocale("long", true);
    const day = this.modal_date.getDate();
    const month = monthNames[this.modal_date.getMonth()];
    const year = this.modal_date.getFullYear();
    this.display_date = day + " " + month + " " + year;
  }

  disposeSpans() {
    const picked_date_spans = this.modal_date.toDateString();
    if (this.date_list[picked_date_spans] != undefined) {
      this.disposed_spans = this.date_list[picked_date_spans].sort(function(
        span1: Therapy.Session,
        span2: Therapy.Session
      ) {
        if (span1.span.start < span2.span.start) return -1;
        else if (span1.span.start > span2.span.start) return 1;
        return 0;
      });
    } else {
      this.disposed_spans = [];
    }
  }
  @Watch("$store.state.calendar.therapistSpans")
  onSpansChanged(val: Therapy.Session[], oldVal: Therapy.Session[]) {
    this.date_list = this.$store.state.calendar.therapistSpans;
    this.disposeSpans();
  }
}
